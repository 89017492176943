import React from "react";
import { useRecoilValue } from "recoil";
import { emergencyPlanState, currentUserState } from "../state/list/ice";
import { useParams } from "react-router-dom";
import Arrow from "../assets/icons/arrow_back_ios_new.svg";
import { useNavigate } from "react-router-dom";

import useFetchEmergencyPlans from "../hooks/useFetchEmergencyPlans";

const EmergencyPlans: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const data = useRecoilValue(emergencyPlanState);
  const navigate = useNavigate();

  const { isLoading, isError } = useFetchEmergencyPlans();

  if (!id) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">Fel ID.</div>
      </div>
    );
  }

  const plan = data?.acf.emergency_plans.find((c) => c.id === id);

  if (!plan) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">Kunde inte hitta checklista.</div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">Laddar...</div>
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">
          Kunde inte hämta checklistor. Försök igen senare.
        </div>
      </div>
    );
  }

  return (
    <div className="font-albert-sans max-w-8xl text-normal-white 2xl:w-[70%] 2k:w-[60%] 2xl:mx-auto">
      <div className="w-full px-6 flex justify-between items-center pt-10 text-lg">
        <div className="flex space-x-3 items-center">
          <div
            onClick={() => {
              navigate("/plan");
            }}
            className="cursor-pointer bg-box-color p-2 rounded-full"
          >
            <img src={Arrow} alt="Arrow" />
          </div>
          <div className="text-normal-white font-albert-sans hidden md:block hover:underline">
            Tillbaka
          </div>
        </div>
        <p className="text-xl md:pr-24 pr-6">{plan.title}</p>
        <div></div>
      </div>
      <div className="text-left pl-6 pr-10 ">
        <div className="pt-16 text-2xl font-semibold">
          <p>{plan.heading}</p>
        </div>
        <div className="pt-2 text-xl">
          <p>{plan.subheading}</p>
        </div>
        <div className="pt-8">
          {plan.description.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
        <div className="pt-8 pb-36 2xl:flex 2xl:justify-center">
          <img src={plan.image} alt="Plan" className="rounded-md" />
        </div>
      </div>
    </div>
  );
};

export default EmergencyPlans;
